<template>
  <div class="softwarefactory">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <LabContent :notiondata="data" lab="softwarefactory" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
      <Contactform
        lab="softwarefactory"
        contactheadline="zur Software Factory"
        team="Prof. Dr. Kai Petersen & Prof. Dr. Jan Christiansen"
        welcomemsg="Computerprogramme schreibt man, damit andere Leute sie lesen können, nicht damit der Computer sie ausführen kann."
      />
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import LabContent from "@/components/notion/LabContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";
import Contactform from "@/components/Contactform.vue";

export default {
  name: "Software Factory",
  components: {
    Meta,
    LabContent,
    BlockContent,
    Contactform,
  },
  data() {
    return {
      NotionPageID: "2f674078f03b4ba99bc07e6209521b22",
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
  },
};
</script>

<style lang="scss"></style>
